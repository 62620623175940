.orangeLogo {
  width: 100%;
  border-style: solid;
  border-color: #ff6b00;
  background-color: #ff6b00;
}
.orangeLogoRow {
  height: 40%;
  margin: 30% 0%;
}
.laptopMockup {
  border-style: solid;
  border-color: #ff6b00;
  background-color: #ff6b00;
  background-image: url(/newLaptop.svg);
  background-size: 110%;
  background-position: right;
  background-repeat: no-repeat;
}
.expandMore {
  border-style: solid;
  border-color: #ff6b00;
  background-color: #ff6b00;
  width: 100%;
  padding-bottom: 5%;
}
.logoImage {
  margin-bottom: 0.5em;
  width: 75%;
}
.mottoText1 {
  width: 75%;
  margin: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  color: #ffffff;
}
.mottoText2 {
  width: 75%;
  margin: 0;
  padding-top: 2%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
}
div#video {
  padding: 5%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.videoClick {
  height: 100%;
  padding-right: 10%;
}
.x {
  position: fixed;
  z-index: 1000;
  top: 80px;
  right: 5%;
  color: white;
}
.trialText,
.tomInfoTitle,
.trialText2,
.trialText2Short,
.reviewsTitle {
  font-family: "Roboto";
  font-size: 24px;
  text-align: center;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 20%;
  margin-right: 20%;
}
.reviewsTitle {
  font-weight: bold;
}
.linkMobile,
.trialText2Short,
.showMobile {
  display: none;
}
.trialButton {
  background: #ff6b00;
  border-radius: 5px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 33px;
  text-align: center;
  color: #ffffff;
  margin-left: 30%;
  margin-right: 30%;
  margin-bottom: 5%;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 64px;
  height: 81px;
}
.buttonMobile {
  display: none;
}
.buttonLargeScreen {
  color: #ffffff;
}
.reviewsSection {
  background-image: url(/ReviewsSquiggle.svg), url(/ReviewsBlob.svg);
  background-position: top 5% left 0%, top 0% left 0%;
  background-repeat: no-repeat;
}
.carousel {
  width: 60%;
  height: auto;
  margin-bottom: 10%;
  left: 20%;
  right: 20%;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}
/* bypassing ant-d button styles */
.ant-carousel .slick-dots li button {
  width: 10px;
  height: 10px;
  margin-bottom: 5px;
  border: 1px solid #878480;
  background-color: #ffffff;
  border-radius: 9px;
}
/* bypassing ant-d width setting */
.ant-carousel .slick-dots li.slick-active {
  width: 16px;
  color: #c6c6c6;
}
/* bypassing ant-d dot color setting */
.ant-carousel .slick-dots li.slick-active button {
  background: #878480;
  opacity: 1;
}
/* bypassing ant-d dot position setting */
.ant-carousel .slick-dots-bottom {
  bottom: -10%;
}
.reviewsBox {
  height: 100%;
}
.centered {
  padding: 5% 15% 0% 15%;
}
.reviewsQuotes {
  position: absolute;
  font-family: "Roboto";
  font-weight: bold;
  font-size: 150px;
  line-height: 176px;
  text-align: right;
  color: #ff6b00;
  transform: rotate(-180deg);
}
.reviewText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #302f2d;
}
.reviewName {
  bottom: 0%;
  font-family: "Roboto";
  font-size: 18px;
  line-height: 21px;
  color: #838383;
  margin-bottom: 10%;
}
.tomInfoText {
  font-family: "Roboto";
  font-size: 24px;
  line-height: 40px;
  color: #878480;
  margin: 0% 5% 5% 5%;
}
.tomInfoText a {
  color: #878480;
}
.tomInfoButton {
  padding: 16px 56px;
  height: 55px;
  background: #174c99;
  border-radius: 5px;
  font-family: "Roboto";
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
  margin-left: 5%;
}
.tomInfoImage {
  width: 85%;
  margin-left: 15%;
}
.tomInfoCaption {
  width: 70%;
  margin-left: 15%;
  font-family: "Roboto";
  font-style: italic;
  font-size: 14px;
  line-height: 18px;
  color: #838383;
}
.blobGroup {
  height: 250px;
  background-image: url(/YellowBlob.svg);
  background-position: top 0% right 0%;
  background-repeat: no-repeat;
  background-size: 30%;
}
.blueBlob {
  position: absolute;
  width: 50%;
  margin-left: 40%;
  margin-top: 10%;
  bottom: -100;
}
/* sm: 500,
md: 768,
lg: 992,
xl: 1200, */
@media only screen and (max-width: 425px) {
  .mottoText1 {
    line-height: 26px;
    font-size: 22px;
  }
}
@media only screen and (min-width: 426px) and (max-width: 767px) {
  .laptopMockup {
    height: 370px;
  }
  .mottoText1 {
    font-size: 30px;
    line-height: normal;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mottoText1 {
    font-size: 30px;
    line-height: 30px;
  }
  .mottoText2 {
    font-size: 18px;
    line-height: 18px;
  }
  .orangeLogoRow {
    margin: 15% 0%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .mottoText1 {
    font-size: 33px;
    line-height: normal;
  }
  .mottoText2 {
    font-size: 22px;
    line-height: normal;
  }
}
/* formatting adjustments for xs through md screens (can be adjusted later) */
@media only screen and (max-width: 767px) {
  .orangeLogo {
    min-height: 150px;
  }
  .orangeLogoRow {
    margin: 5% 0% 5% 0%;
  }
  .laptopMockup {
    min-height: 220px;
    background-size: 100%;
    background-position: center;
  }
  #freeTrial,
  #reviews,
  .reviewsSection,
  #tomInfo,
  #tomLeft,
  #tomRight,
  #freeTrial2 {
    top: 0;
  }
  .logoImage {
    width: 50%;
    margin: 5% 25% 5% 25%;
  }
  .mottoText1 {
    text-align: center;
    width: 80%;
    margin: 0% 10% 0% 10%;
  }
  .mottoText2 {
    text-align: center;
    line-height: 18px;
    font-size: 18px;
    width: 80%;
    margin: 0% 10% 3% 10%;
  }
  .laptopImage {
    position: relative;
    top: 0%;
    max-width: 80%;
    margin: 0% 10% 0% 10%;
  }
  .videoClick {
    padding-right: 0%;
  }
  /* ELEMENTS THAT ARE NOT IN MOBILE VERSION */
  .reviewsQuotes,
  .trialText,
  .tomInfoCaption,
  .trialText2,
  .tomInfoButton,
  .buttonLargeScreen,
  .hideMobile {
    display: none;
  }
  /* ELEMENTS ONLY IN MOBILE VERSION */
  .trialText2Short,
  .buttonMobile,
  .linkMobile,
  .showMobile {
    display: block;
  }
  .trialButton {
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 10%;
    width: 60%;
    font-size: 18px;
  }
  .carousel {
    width: 80%;
    left: 10%;
    right: 10%;
  }
  .reviewText,
  .reviewName {
    position: relative;
    width: 100%;
    font-size: 16px;
    line-height: 26px;
    padding: 5% 5% 0% 5%;
  }
  .tomInfoCaption,
  .tomInfoImage,
  .tomInfoText {
    width: 80%;
    margin-right: 10%;
    margin-left: 10%;
    font-size: 16px;
    line-height: 26px;
  }
  .tomInfoText {
    padding-top: 3%;
  }
  .trialText2,
  .tomInfoTitle,
  .trialText2Short,
  .reviewsTitle {
    font-size: 18px;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .reviewsSection {
    background-image: url(/ReviewsSquiggle1.svg), url(/ReviewsBlob.svg);
    background-position: top 0% left 0%, top 0% left 0%;
    background-repeat: no-repeat;
  }
  .blobGroup {
    height: 100px;
  }
  .buttonMobile {
    color: #ffffff;
  }
  .tomInfoText a {
    color: #2d5199;
  }
}
