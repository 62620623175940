/*
 * Work around a bug in antd where a <Button type="primary" block href="...">
 * has blue text on blue background
 */
a.ant-btn-primary {
  color: white;
}
a.ant-btn-primary:hover {
  color: #f0faff;
}
.ant-progress-success-bg,
.ant-progress-bg {
  transition: all 0.5s ease 0s;
}
.ant-progress-inner {
  padding-top: 1px;
}
/** Faster animation for couplet progress bar **/
.ant-progress-success-bg,
.pg-playing-bg .ant-progress-bg {
  transition: all 0.1s ease 0s;
}
.ant-radio-button-wrapper:hover {
  color: #fff;
  background-color: #2d5199;
}
.ant-btn:hover,
.ant-btn:focus {
  color: #2d5199;
  border-color: #2d5199;
}
.ant-slider-track {
  background-color: #2d5199;
}
.ant-slider:hover .ant-slider-track {
  background-color: #2d5199;
}
.ant-switch-checked {
  background-color: #2d5199;
}
/* RangePicker default rage value **/
.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  background: #fff;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
}
.ant-progress-inner {
  border-radius: 0px;
  padding-top: 0px;
}
span.ant-radio + * {
  width: 100%;
}
.ant-dropdown .ant-card {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}
